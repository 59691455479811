<template>
  <div class="page-content-inner pt-lg-0  pr-lg-0 mt-4">
    <h3>{{ $t('education.catalog_education') }}</h3>
    <div v-if="errorMessages?.length" class="uk-alert-danger" uk-alert>
      <template v-for="(error, index) in errorMessages">
        <span :key="index" v-if="error != null">
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red" class="m-0">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </span>
      </template>
    </div>
    <div v-if="successMessage" class="uk-alert-success" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p style="color: green">
        <i class="uil-exclamation-triangle"></i> {{ successMessage }}
      </p>
    </div>
    <div v-if="!defaultLoadingModal" class="section-header pb-0 mb-4">
      <div class="section-header-left">
        <!-- <form @submit.prevent="applyFilter"> -->
        <input v-model="searchQuery" :placeholder="$t('home.Education_shearing')" class="mt-3" type="text"
          @input="isTyping = true">
        <!-- </form> -->
      </div>
      <div class="section-header-right">
        <select v-model="order_by" class="ml-3 mb-0" @change="applyFilter">
          <option value="name">{{ $t('filter.Filter_by_name') }}</option>
          <option value="training_type_id">{{ $t('filter.Filter_by_category') }}</option>
          <option value="created_at">{{ $t('filter.Filter_by_date') }}</option>
        </select>
      </div>
    </div>
    <div class="ro" uk-grid v-if="!defaultLoadingModal">
      <div class="uk-width-1-3@m">
        <nav class="responsive-tab style-3 setting-menu card"
          uk-sticky="top:30 ; offset:100; media:@m ;bottom:true; animation: uk-animation-slide-bottom">
          <ul class="side-list" uk-accordion>
            <li class="">
              <a class="uk-accordion-title" href="#"><i class="uil-book-alt"></i> {{ $t('home.Categories') }}</a>
              <div class="uk-accordion-content">
                <ul class="ks-cboxtags">
                  <li v-for="(item, index) in educationTypes" :key="index">
                    <input id="checkboxOne" v-model="options" :value="item.id" type="checkbox" @change="applyFilter">
                    <label for="checkboxOne">
                      {{ item.name }}
                    </label>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a class="uk-accordion-title" href="#"><i class="uil-notes"></i> {{ this.$t('general.training_types')
                }}</a>
              <div class="uk-accordion-content">
                <ul class="ks-cboxtags">
                  <li>
                    <input id="checkboxTypeOne" checked v-model="kinds" :value="0" type="checkbox"
                      @click="applyFilter($event)">
                    <label for="checkboxTypeOne">Hepsi</label>
                  </li>
                  <li v-for="(item, index) in formattedTrainingKind" :key="index">
                    <input id="checkboxTwo" v-model="kinds" :value="item.id" type="checkbox"
                      @click="applyFilter(item.id)">
                    <label for="checkboxTwo">
                      {{ item.name }}
                    </label>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <!--super admin için-->
      <div class="uk-width-2-3@m" v-if="superAdmin()">
        <div class="card rounded">
          <div class="p-3 d-flex align-items-center justify-content-between">
            <h5 class="mb-0"> {{ selected_type.name }}
              <span style="font-weight:300;">({{
      items ? items.total : 0
    }} {{ $t('education.education') }} görüntüleniyor)</span>
            </h5>
            <div>
              <button type="button" class="btn btn-sm btn-soft-secondary mr-2" @click="allUserToggle()">
                <i class="uil-check-square"></i> {{ selectAllTrainingText }}
              </button>
              <button class="btn btn-success ml-1 " :class="{
      'disabled': training_ids?.length === 0
    }" @click="openLicenceModal()"><i class="uil-location-arrow"></i>
                Seçilenleri Lisansla</button>
              <div id="modal_catalog_add" class=" uk-modal" uk-modal="">

                <div class="uk-modal-dialog" style="width:100%">

                  <button class="uk-modal-close-default" type="button" uk-close></button>
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Lisanslama ( {{ training_ids?.length }} Eğitim Seçildi)</h2>
                  </div>
                  <div class="card">
                    <div v-if="errorMessages?.length" class="uk-alert-danger" uk-alert>

                      <template v-for="(error, index) in errorMessages">
                        <span :key="index" v-if="error != null">
                          <a class="uk-alert-close" uk-close></a>
                          <p style="color: red" class="m-0">
                            <i class="uil-exclamation-triangle"></i> {{ error }}
                          </p>
                        </span>
                      </template>
                    </div>
                    <div v-if="successMessage" class="uk-alert-success" uk-alert>
                      <a class="uk-alert-close" uk-close></a>
                      <p style="color: green">
                        <i class="uil-exclamation-triangle"></i> {{ successMessage }}
                      </p>
                    </div>
                    <!-- Card header -->
                    <div class="card-header actions-toolbar border-0">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="row" style="width:100%">
                          <div class=" col-md-1  search-icon ">
                            <i class="uil-search"></i>
                          </div>
                          <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                            <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                              @change="filterResult" :placeholder="$t('general.search')" autofocus="" />
                          </div>
                        </div>
                        <div class="d-flex">
                          <!-- Shorting -->
                          <a href="javascript:void(0)" class="btn btn-icon btn-hover  btn-circle"
                            :uk-tooltip="$t('filters.order.sort')" title="" aria-expanded="false">
                            <i class="uil-list-ui-alt"></i>
                          </a>
                          <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up"
                            class="uk-dropdown">
                            <ul class="uk-nav uk-dropdown-nav">
                              <li class="uk-active"><a href="javascript:void(0)" @click="sortOrder('name', 'asc')">{{
      $t('filters.order.a_z') }}</a></li>
                              <li><a href="javascript:void(0)" @click="sortOrder('name', 'desc')">{{
      $t('filters.order.z_a') }}</a>
                              </li>
                              <li><a href="javascript:void(0)" @click="sortOrder('created_at', 'asc')"> {{
      $t('filters.order.created_at_asc') }}</a></li>
                              <li><a href="javascript:void(0)" @click="sortOrder('created_at', 'desc')"> {{
      $t('filters.order.created_at_desc') }}</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Table -->
                  <div class="table-responsive">
                    <table class="table align-items-center">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">{{ $t('institution.list.company_name') }}</th>
                          <th scope="col">{{ $t('institution.list.contract_expired_date') }}</th>
                          <th scope="col">{{ $t('institution.list.created_at') }}</th>
                          <th scope="col">{{ $t('Kullanıcı Sayısı') }}</th>
                          <th scope="col"> </th>
                        </tr>
                      </thead>
                      <tbody class="list">
                        <!--user item-->
                        <tr v-for="institution in formattedItem">
                          <th>
                            <div style="display:flex;justify-content:center">
                              <input v-if="training_ids?.length > 1" type="radio"
                                :id="'notification_checkbox_' + institution.id" v-model="company_ids"
                                style="margin: 0px 0px 0px 10px" :value="institution.id">
                              <input v-if="training_ids?.length < 2" :id="'notification_checkbox_' + institution.id"
                                :value="institution.id" v-model="company_ids" style="margin: 0px 0px 0px 0px"
                                type="checkbox" />
                            </div>
                          </th>
                          <th scope="row">
                            <div class="media align-items-center">
                              <div>
                                <div class="avatar-parent-child" style="width: max-content">
                                  <img alt="Image placeholder" :src="institution.logo_link"
                                    class="avatar  rounded-circle">
                                </div>
                              </div>
                              <div class="media-body ml-4">
                                <a class="name h6 mb-0 text-sm">{{ institution.name }}</a>
                                <small class="d-block font-weight-bold">#{{ institution.id }}</small>
                              </div>
                            </div>
                          </th>
                          <td>{{ institution.end_date }}</td>
                          <td> {{ institution.created_at }} </td>
                          <td> {{ institution.users_count }} </td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <pagination :pagination="pagination" @changePage="setPage($event)" :page.sync="pageProxy">
                  </pagination>
                  <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-modal-close" type="button">Vazgeç</button>
                    <button class="uk-button uk-button-primary ml-2" @click="saveCheckedInstitution()"
                      type="button">Kaydet</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <template v-for="(subItem, index) in items.data" v-if="items && items.data && items.data?.length">
            <hr class="m-0">
            <div class="uk-grid-small p-4 position-relative" uk-grid>
              <div class="ribbon ribbon-top-left" v-if="subItem.self_registrable_trainings?.length > 0
      && subItem.company_id === 1" style="height:50%">
                <span>KİRALANDI</span>
              </div>
              <div class="ribbon ribbon-top-left"
                v-if="subItem.self_registrable_trainings?.length > 0 && subItem.company_id !== 1" style="height:50%">
                <span>EĞİTİM</span>
              </div>
              <div class="row" style="margin-top:4px ;width: 100%;">
                <div class="col-md-2">
                  <input v-model="training_ids" :id="'notification_checkbox_' + subItem.id" :value="subItem.id"
                    style="width: 15px; margin-top: 38px" type="checkbox" />
                </div>
                <div class="col-md-4">
                  <img :src="subItem.cover_image_link" alt="" class="rounded">
                </div>
                <div class="col-md-6">
                  <a>
                    <h5 class="mb-2">
                      {{ subItem.name }} <span style="color:#36b37e" v-if="subItem.catalog_anz_education_id">(Katalogdan
                        çekilmiştir) </span><span style="color:#36b37e" v-if="subItem.company_id !== 1">(Kurum
                        Eğitimi)</span>
                      <span class="float-right d-flex" style="margin-top:9px">
                        <a v-if="!subItem.show_to_user" href="javascript:void(0)"
                          class="btn btn-outline-danger btn-sm ml-2" @click="changeStatus(subItem.id)"
                          uk-tooltip="Kurumlar Görmüyor"><i class="uil-eye-slash"></i></a>
                        <a v-else href="javascript:void(0)" class="btn btn-outline-success btn-sm ml-2"
                          @click="changeStatus(subItem.id)" uk-tooltip="Kurumlar Görüyor"><i class="uil-eye"></i></a>
                        <a href="javascript:void(0)" class="btn btn-icon btn-hover btn-sm btn-circle ml-2"
                          uk-tooltip="Düzenle; pos: bottom" @click="handleOnClickCard('goEditPage', subItem.id)"><i
                            class="uil-edit"></i></a>
                        <a href="javascript:void(0)" class="btn btn-icon btn-hover btn-sm btn-circle ml-2"
                          uk-tooltip="Sil; pos: bottom"
                          @click="handleOnDeleteCard(subItem.id, subItem.has_been_assigned)"><i
                            class="uil-trash text-danger"></i></a>
                      </span>
                    </h5>
                    <p class="mb-0 uk-text-small mt-3">
                      <span class="mr-3 bg-light p-2 mt-1"> {{
      $t('education.section_count', { 'number': subItem.training_content_count }) + ' / ' +
      $t('education.content_count', { 'number': subItem.training_content_count })
    }}</span>
                    </p>
                    <p class="uk-text-small mb-1 mt-2">{{ subItem.description }}</p>
                    <p class="uk-text-small mb-1 mt-1">{{ subItem.training_type?.name }}</p>
                  </a>
                </div>
              </div>
            </div>
          </template>
        </div>
        <PaginationTable v-if="items && items.data && items.last_page !== 1" :page.sync="pageProxy" :paginate="items">
        </PaginationTable>
      </div>
      <!--kurum admin için-->
      <div class="uk-width-2-3@m" v-if="!superAdmin()">

        <div class="card rounded">
          <div class="p-3 d-flex align-items-center justify-content-between">
            <h5 class="mb-0"> {{ selected_type.name }}
              <span style="font-weight:300;">({{
      items ? items.total : 0
    }} {{ $t('education.education') }} görüntüleniyor)</span>
            </h5>
            <!-- <div>
              <button type="button" class="btn btn-sm btn-soft-secondary mr-2" @click="allUserToggle()">
                <i class="uil-check-square"></i> {{ selectAllTrainingText }}
              </button>
            </div> -->
          </div>
          <template v-for="(subItem, index) in items.data" v-if="items && items.data && items.data?.length">
            <hr class="m-0">
            <div class="uk-grid-small p-4 position-relative" uk-grid>
              <div v-if="subItem.self_registrable_trainings?.length > 0" class="ribbon ribbon-top-left"
                style="z-index:1;">
                <span>KİRALANDI</span>
              </div>
              <div class="ribbon ribbon-top-left" v-if="subItem.company_id === userInfo?.company_id" style="z-index:1;">
                <span>Eğitim</span>
              </div>
              <div class="row w-100">
                <!-- <div class="col-md-1">
                  <input v-model="self_registrable_training_ids" :id="'notification_checkbox_' + subItem.id"
                    :value="subItem.id" style="width: 15px; margin-top: 38px" type="checkbox" />
                </div> -->
                <div class="col-md-3 p-0">
                  <img :src="subItem.cover_image_link" alt="" class="rounded">
                </div>
                <div class="col-md-5 d-flex flex-column">
                  <h5 class="mb-0">{{ subItem.name }}</h5>
                  <p class="mb-0 uk-text-small">
                    <span class="mr-3 bg-light mt-1"> {{
      $t('education.section_count', { 'number': subItem.training_content_count }) + ' / ' +
      $t('education.content_count', { 'number': subItem.training_content_count })
    }}</span>
                    <span class="d-block">{{ subItem.description }}</span>
                  </p>
                  <p class="uk-text-small mb-1 mt-1">{{ subItem.training_type?.name }}</p>

                </div>
                <div class="col-md-4">
                  <div class="row mb-2 d-flex justify-content-end">
                    <!-- <a href="javascript:void(0)" v-if="subItem.self_registrable_trainings?.length > 0"
                      class="btn btn-primary btn-sm float-right mb-1" style="font-size: 0.6rem !important"
                      @click="settings(subItem)" uk-toggle="target: #modal-overflow"><i class="uil-location-arrow"></i>
                      {{ $t('menu.company_dashboard.create_assignment') }}</a> -->
                    <!-- <button class="btn btn-sm btn-default mb-1" type="button" aria-expanded="false">
                      <i class="uil-location-arrow"> </i>{{ $t('menu.company_dashboard.create_assignment') }}
                    </button> -->

                    <a href="javascript:void(0)" class="btn btn-sm btn-primary" @click="showUser(subItem.id)"><i
                        class="uil-eye"></i> {{ $t('general.show_to_users') }}
                    </a>
                  </div>
                  <div class="row d-flex justify-content-end">
                    <button type="button" class="btn btn-icon btn-hover btn-sm btn-circle ml-2"
                      uk-tooltip="Demo; pos: bottom" @click="handleOnClickCard('goDemoPage', subItem.id)"><i
                        class="uil-play-circle"></i>
                    </button>
                    <!-- <a v-if="+subItem.show_to_user === 0" href="javascript:void(0)"
                      class="btn btn-outline-danger btn-sm ml-2" @click="changeStatus(subItem.id)"
                      uk-tooltip="Kullanıcılar Görmüyor"><i class="uil-eye-slash"></i></a>
                    <a v-else href="javascript:void(0)" class="btn btn-outline-success btn-sm ml-2"
                      @click="changeStatus(subItem.id)" uk-tooltip="Kullanıcılar Görüyor"><i class="uil-eye"></i></a> -->
                    <a v-if="subItem.company_id === userInfo?.company_id" href="javascript:void(0)"
                      class="btn btn-icon btn-hover btn-sm btn-circle ml-2" uk-tooltip="Düzenle; pos: bottom"
                      @click="handleOnClickCard('goEditPage', subItem.id)"><i class="uil-edit"></i></a>
                    <a v-if="subItem.company_id === userInfo?.company_id" href="javascript:void(0)"
                      class="btn btn-icon btn-hover btn-sm btn-circle ml-2" uk-tooltip="Sil; pos: bottom"
                      @click="handleOnDeleteCard(subItem.id, subItem.has_been_assigned)"><i
                        class="uil-trash text-danger"></i></a>
                  </div>

                </div>
              </div>

            </div>

          </template>
        </div>
        <PaginationTable v-if="items && items.data && items.last_page !== 1" :page.sync="pageProxy" :paginate="items">
        </PaginationTable>

      </div>

      <div id="shareSettings" class="uk-modal" uk-modal>
        <div class="uk-modal-dialog bggray" style="width:100%">
          <div class="uk-padding-small" uk-grid>
          </div>
          <div class="row p-4 position-relative">
            <!--eğitime kayıtlı kullanıcı varsa confirm çıkıyor-->
            <!-- <div class="uk-card uk-card-default uk-width-1-1@m registered-user-confirm">
                <div class="uk-card-header">
                    <div class="uk-grid-small uk-flex-middle" uk-grid>
                        <div class="uk-width-auto">
                          <i class="uil-exclamation-octagon uk-text-danger" style="font-size:3rem;"></i>
                        </div>
                        <div class="uk-width-expand">
                            <h3 class="uk-card-title uk-margin-remove-bottom uk-text-danger">Uyarı!</h3>
                            <p class="uk-text-danger uk-text-bold uk-text-default uk-margin-remove-top" style="font-size:1rem">Kayıt Olan Kullanıcılar Var</p>
                          </div>
                    </div>
                </div>
                <div class="uk-card-body p-3">
                    <p class="uk-text-bold">Eğitimin bu kullanıcılara gösterimini kaldırmak istediğinizden emin misiniz?</p>
                    <ul class="uk-list uk-list-divider">
                      <li class="d-flex"><i class="uil-user uk-text-danger uk-text-larger mr-1"></i> <span>Burakhan Karatepe (burakhan.karatepe@anzera.com.tr)</span></li>
                      <li class="d-flex"><i class="uil-user uk-text-danger uk-text-larger mr-1"></i> <span>Emrah Erol (emrah.erol@anzera.com.tr)</span></li>
                    </ul>
                  </div>
                <div class="uk-card-footer p-2 d-flex justify-content-between">
                  <button class="uk-button uk-button-default">Vazgeç</button>
                    <button class="uk-button uk-button-primary">Kaldır</button>
                </div>
              </div>      -->

            <UserGroupSelection @setCheckedUsers="($event) => {
      checked_users = $event;
      checked_users_items = $event.items;
    }
      " @setCheckedGroups="($event) => {
      checked_groups = $event.ids;
      checked_groups_items = $event.items;
    }
      " :added_users="checked_users" :added_groups="checked_groups" :checked_training="checked_training"
              :case_detail="!superAdmin() ? 'usersRequestedTraining' : 'adminSelfRegistrableTraining'">
            </UserGroupSelection>
          </div>
          <div class="uk-modal-footer uk-text-right bggray">
            <button class="uk-button uk-button-primary uk-modal-close" type="button" @click="saveCheckedUser()">
              <i class="uil-times"></i> Kaydet ve Kapat
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <AddToGroupModal :title="'Kullanıcı Ekle'" @getList="getList($event)"></AddToGroupModal> -->
    <default-loading v-if="defaultLoadingModal"></default-loading>
  </div>
</template>

<script>
import EducationCard from "@/view/components/index/EducationCard";
import PaginationTable from "@/view/components/dataTable/Pagination";
import Pagination from "@/assets/components/Pagination";

import moment from "moment";
import store from '@/core/services'
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import axios from "axios";

import { GET_ITEMS, ITEMS, LOADING, DELETE_ITEM_BY_ID } from "@/core/services/store/REST.module";
import { mapGetters } from 'vuex'
import AddToGroupModal from "@/view/company/User/Group/AddToGroupModal";
import training_kind_module, {
  BASE_URL as TRAINING_KIND_URL,
  GET_ITEMS as GET_TRAINING_KINDS,
  MODULE_NAME as MODULE_TRAINING_KIND,
  ITEMS as TRAINING_KINDS
} from "@/core/services/store/training_kind.module";
import module, {
  BASE_URL as COMPANY_URL,
  GET_ITEMS as GET_COMPANY,
  ITEMS as ITEMS_COMPANY,
  MODULE_NAME as MODULE_COMPANY,
} from "@/core/services/store/company.module";
import Swal from "sweetalert2";
import {
  GET_ITEM_DETAIL_BY_ID,
  UPDATE_ITEM_BY_ID,
  CREATE_ITEM
} from "@/core/services/store/REST.module";
import UserGroupSelection from "@/view/components/assignment/UserGroupSelection";
const _TRAINING_KIND_MODULE = MODULE_TRAINING_KIND;

export default {
  name: "EducationCatalog",
  components: {
    EducationCard,
    Pagination,
    PaginationTable,
    DefaultLoading,
    UserGroupSelection,
    AddToGroupModal
  },
  beforeCreate() {
    // this.defaultLoadingModal = true;
    // function registerStoreModule(moduleName, storeModule) {
    //   if (!(store && store.state && store.state[moduleName])) {
    //     store.registerModule(moduleName, storeModule)
    //   }
    // }
    // registerStoreModule(_TRAINING_KIND_MODULE, training_kind_module)
  },
  data() {
    return {
      defaultLoadingModal: true,
      trainingUrl: "api/training/",
      trainingAssignUrl: "api/training/assign-users/",
      company_ids: [],
      selectAllTrainingText: "Tümünü Seç",
      search: null,
      searchQuery: "",
      isTyping: false,
      searchResult: [],
      options: [0],
      kinds: [0],
      order_by: 'name',
      sort: 'desc',
      restUrl: 'api/training',
      educationTypes: [],
      selected_type: {
        id: null,
        name: this.$t('education.all_categories')
      },
      filters: {
        page: 1,
        search: '',
        training_type_id: 0,
        training_kind_id: 0,
        admin_page: true,
        can_show_self_registrable_trainings: true,
        order_by: null,
        sort: null
      },
      successMessage: null,
      errorMessages: [],
      selected_type_name: null,
      trainingCategoryUrl: 'api/training-type',
      page: 1,
      checked_groups: [],
      checked_users: [],
      checked_training: null,
      self_registrable_training_ids: [],
      filter: {
        search: null,
        order_by: null,
        sort: null
      },
      pagination: null,
      training_ids: [],
      allTraining: false,
      allSelfRegistrableTraining: false
    };
  },
  computed: {
    userInfo: {
      get: function () {
        return JSON.parse(this.secureLocalStorage("user"));
      }
    },
    institutions: {
      get() {
        return store.getters[MODULE_COMPANY + '/' + ITEMS_COMPANY]
      },
      set(value) {
      }
    },
    items: {
      get() {
        return store.getters[MODULE_TRAINING_KIND + '/' + TRAINING_KINDS]
      },
      set(value) {
      }
    },
    formattedItem() {
      let results = [];
      if (this.institutions === null || !("data" in this.institutions)) return [];
      for (let i = 0; i < this.institutions.data?.length; i++) {
        let currentItem = this.institutions.data[i];
        this.pagination = this.institutions;
        if (currentItem) {
          results.push({
            id: currentItem.id,
            logo_link: currentItem.logo_link,
            name: currentItem.name,
            status_id: currentItem.status_id,
            users_count: currentItem.users_count,
            created_at: currentItem.created_at ? moment(currentItem.created_at).format('DD/MM/YYYY HH:mm:ss') : '-',
            end_date: (currentItem.contract) ? currentItem.contract.end_date : "-"
          });
        }
      }
      return results;

    },
    ...mapGetters({
      items: ITEMS,
      catalog_loading: LOADING
    }),
    training_kinds: {
      get() {
        return store.getters[_TRAINING_KIND_MODULE + '/' + TRAINING_KINDS]
      },
      set(value) {
      }
    },
    formattedTrainingKind() {
      let results = [];
      if (!this.training_kinds) return [];
      if (this.training_kinds === null || !("data" in this.training_kinds)) return [];
      this.training_kinds.data.forEach(item => {
        results.push({
          id: item.id,
          value: item.id,
          name: item.name
        });
      });
      return results;
    },
    pageProxy: {
      set(value) {
        this.page = value
        this.applyFilter()
      },
      get() {
        return this.page;
      }
    },
    user_header: {
      get() {
        return JSON.parse(this.secureLocalStorage("user"));
      },
      set() { },
    },
  },
  methods: {
    getList(value) {
      let self = this
      let users_count_left = self.selected_purchase.number_of_users - self.selected_purchase.assigned_users_count;
      if (value.length > users_count_left) {
        self.scrollTop()
        self.errorMessage = 'İzin verilen maksimum kullanıcı sayısı ' + users_count_left + '\'dır.'
        return false
      }
      let formData = new FormData();
      value.forEach(item => {
        formData.append("users[]", item.id);
      })
      formData.append("purchase_id", self.selected_purchase.id);
      self.$store
        .dispatch(CREATE_ITEM, {
          url: self.trainingAssignUrl + self.selected_purchase.id,
          contents: formData,
          acceptPromise: true,
        })
        .then((result) => {
          if (!self.error) {
            // let data = result.data;
            // self.selected_purchase.assigned_users_count = data.purchase_assigned_users_count
            // self.item.total_users_allowed = data.total_users_allowed
            // self.item.assigned_users = data.assigned_users
            self.successMessage = "Atama işlemi başarıyla tamamlandı.";
          } else self.errorMessage = self.error;
        });
    },
    settings(purchase_item) {
      this.selected_purchase = purchase_item
      this.getTrainingDetails(purchase_item.id)
    },
    getTrainingDetails(id) {
      this.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: this.trainingUrl + id,
          filters: { with_requests: true },
          acceptPromise: false,
        })
        .then((result) => {
          if (result.status) {
            this.item = result.data;
          }
        });
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    saveCheckedInstitution() {
      if (!Array.isArray(this.company_ids)) {
        this.company_ids = [this.company_ids]
      }
      let sendData = {
        'company_ids': this.company_ids,
        // 'training_ids': this.allTraining ? 'all' : this.training_ids,
        'training_ids': this.training_ids,

      }
      this.$store.dispatch(CREATE_ITEM, {
        url: 'api/self-registrable-trainings',
        contents: sendData,
        acceptPromise: true,
      })
        .then((response) => {
          this.scrollToTop();
          this.training_ids = [];
          this.selectAllTrainingText = 'Tümünü Seç';
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_created");
            this.applyFilter()
          } else {
            this.errorMessages.push(this.error)
          }
          if (response) {
            // this.applyFilter()

            setTimeout(() => {
              this.resetMessages();
              UIkit.modal("#modal_catalog_add").hide();
              this.training_ids = [];
              this.company_ids = [];
            }, 1000);
          }
        });
    },
    superAdmin() {
      if (this.user_header === null || !("roles" in this.user_header)) {
        return false;
      }
      if (this.user_header.roles.length > 2) {
        return true;
      } else {
        return false;
      }
    },
    openLicenceModal() {
      // this.$store.dispatch(MODULE_COMPANY + '/' + GET_COMPANY, {
      //   url: COMPANY_URL,
      //   filters: {
      //     type: this.type,
      //     like: this.filter.search,
      //     page: this.page,
      //     per_page: this.per_page,
      //     order_by: this.filter.order_by,
      //     sort: this.filter.sort,
      //   }
      // }).then(response => {
      if (this.training_ids.length > 1) {
        this.company_ids = [];
      } else {
        this.items.data.every(item => {
          if (item.id === this.training_ids[0]) {
            this.company_ids = item.self_registrable_trainings?.reduce((acc, curr) => {
              // if (!acc.includes(curr.pivot.company_id)) {
              acc.push(curr.pivot.company_id)
              return acc;
              // }
              // return acc
            }, [])
            return false
          }
          else {
            return true
          }
        })
        // this.items.data.self_registrable_trainings
      }
      UIkit.modal("#modal_catalog_add").show();
    },

    filterResult() {
      this.$store.dispatch(MODULE_COMPANY + '/' + GET_COMPANY, {
        url: COMPANY_URL,
        filters: {
          type: this.type,
          like: this.filter.search,
          page: this.page,
          per_page: 6,
          order_by: this.filter.order_by,
          sort: this.filter.sort,
        }
      })
    },
    sortOrder(order_by, sort) {
      this.page = 1;
      this.filter.order_by = order_by;
      this.filter.sort = sort;

      this.filterResult();
    },
    setPage(page) {
      this.page = page;
      this.filterResult()
    },
    allUserToggle() {
      if (this.selectAllTrainingText === 'Tümünü Seç') {
        if (this.superAdmin()) {
          this.items.data.forEach((training) => {
            let isSelfTraining = false
            this.training_ids?.every((selfTraining) => {
              if (selfTraining === training.id) {
                isSelfTraining = true
                return true
              } else {
                return true
              }
            });
            if (!isSelfTraining)
              this.training_ids.push(training.id)
          });
          // this.items.data.forEach(training => this.training_ids.push(training.id))
          this.allTraining = true;
        } else {
          this.items.data.forEach((training) => {
            let isSelfTraining = false
            this.self_registrable_training_ids?.every((selfTraining) => {
              if (selfTraining === training.id) {
                isSelfTraining = true
                return true
              } else {
                return true
              }
            });
            if (!isSelfTraining)
              this.self_registrable_training_ids.push(training.id)
          });
          // this.items.data.forEach(training => this.self_registrable_training_ids.push(training.id));
          this.allSelfRegistrableTraining = true;
        }
        this.selectAllTrainingText = 'Tümünü Kaldır';
      } else {
        if (this.superAdmin()) {
          this.items.data.forEach((training) => {
            let isSelfTraining = false
            let selfIndex = 0;
            this.training_ids?.every((selfTraining, index) => {
              if (selfTraining === training.id) {
                isSelfTraining = true
                selfIndex = index;
                return true
              } else {
                return true
              }
            });
            if (isSelfTraining)
              this.training_ids.splice(selfIndex, 1);
          });
          this.allTraining = false
        } else {
          this.items.data.forEach((training) => {
            let isSelfTraining = false
            let selfIndex = 0;
            this.self_registrable_training_ids?.every((selfTraining, index) => {
              if (selfTraining === training.id) {
                isSelfTraining = true
                selfIndex = index;
                return true
              } else {
                return true
              }
            });
            if (isSelfTraining)
              this.self_registrable_training_ids.splice(selfIndex, 1);
          });
          this.allSelfRegistrableTraining = false;
        }
        this.selectAllTrainingText = 'Tümünü Seç';
      }
    },
    showUser(event) {
      this.checked_users = [];
      let checkedTraining = this.items.data.filter(data => data.id == event)
      checkedTraining[0].user_self_register_trainings.forEach(user => {
        this.checked_users.push(user.user_self_register_trainingable_id);
      });
      this.checked_training = event;
      UIkit.modal("#shareSettings").show();
    },
    saveCheckedUser() {

      let formData = new FormData();
      for (let i = 0; i < this.checked_users?.length; i++) {
        formData.append('user_ids[]', this.checked_users[i]);
      }
      let sendData = {
        // 'user_ids': this.allSelfRegistrableTraining ? 'all' : this.checked_users
        'user_ids': this.checked_users
      }
      this.$store.dispatch(UPDATE_ITEM_BY_ID, {
        url: 'api/trainings/' + this.checked_training + '/user-self-register-trainings',
        contents: sendData,
        acceptPromise: false,
      });
      this.$store.dispatch(GET_ITEMS, {
        url: this.restUrl,
        acceptPromise: false,
        showLoading: true,
        filters: this.filters,
      }).then((response) => {
        this.items = response
      })
      // this.$store.dispatch(GET_ITEMS, {
      //   url: 'api/trainings/'+10+'user-self-register-trainings',
      //   acceptPromise: false,
      //   filters: this.filters,
      //   showLoading: true,
      // })
    },
    changeStatus(id) {
      axios.get(`/api/set-training-visibility/${id}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.secureLocalStorage("token"),
        }
      }).then(() => {
        this.applyFilter()
      })
    },
    searchUser: function (searchQuery) {
      let self = this;
      this.$set(this.filters, 'page', this.page)
      // this.$set(this.filters, 'training_type_id', this.selected_type.id)
      this.$set(this.filters, 'training_type_id', this.options)
      this.$set(this.filters, 'training_kind_id', this.kinds)
      this.$set(this.filters, 'search', this.searchQuery)
      this.$set(this.filters, 'order_by', this.order_by);
      this.$store.dispatch(GET_ITEMS, {
        url: this.restUrl,
        acceptPromise: false,
        filters: this.filters,
        showLoading: true,
      })
    },
    deleteCatalogEducation(id) {
      this.$store
        .dispatch(DELETE_ITEM_BY_ID, {
          url: this.restUrl + "/" + id,
        })
        .then(() => {
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_deleted");
            this.applyFilter()
          } else {
            this.errorMessages.push(this.error)
          }
        });
    },
    handleOnDeleteCard(id, has_been_assigned) {
      let self = this;
      if (has_been_assigned) {
        Swal.fire({
          title: "Bu eğitimin ataması yapılmış, silmek istediğinizden emin misiniz?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Vazgeç",
          confirmButtonColor: "#e31748",
        }).then((result) => {
          if (result.isConfirmed) {
            self.deleteCatalogEducation(id);
          }
        });
        return false;
      }

      Swal.fire({
        title: "Silmek istediğinizden emin misiniz?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Vazgeç",
        confirmButtonColor: "#e31748",
      }).then((result) => {
        if (result.isConfirmed) {
          self.deleteCatalogEducation(id);
        }
      });
    },
    handleOnClickCard(event, payload) {
      if (event === 'goDemoPage') {
        this.$router.push({ name: 'company.requests.training', params: { id: payload } })
      } else if (event === 'goEditPage') {
        this.$router.push({ name: 'company.education.catalog.edit', params: { id: payload } })

      }
    },
    fixDate(value) {
      return moment().seconds(value).toNow();
    },
    applyFilter(event = null) {
      if (event?.target?.checked && event?.target?._value === 0) {
        this.kinds = [0, 1, 2, 3];
      } else if (!event?.target?.checked && event?.target?._value === 0) {
        this.kinds = [];
      }
      this.$set(this.filters, 'page', this.page)
      // this.$set(this.filters, 'training_type_id', this.selected_type.id)
      if (!(this.options.length === 1 && this.options[0] === 0)) {
        this.$set(this.filters, 'training_type_id[]', this.options)
      }
      if (!(this.kinds.length === 1 && this.kinds[0] === 0)) {
        this.$set(this.filters, 'training_kind_id[]', this.kinds)
      }
      this.$set(this.filters, 'search', this.search)
      this.$set(this.filters, 'order_by', this.order_by)
      this.$store.dispatch(GET_ITEMS, {
        url: this.restUrl,
        acceptPromise: false,
        filters: this.filters,
      }).then((response) => {
        this.defaultLoadingModal = false
        if (response) {
          this.items.data = response.data.data
          // if (this.allTraining) {
          //   this.items.data.forEach(training => this.training_ids.push(training.id))
          // }
          // if (this.allSelfRegistrableTraining) {
          //   this.items.data.forEach(training => this.self_registrable_training_ids.push(training.id));
          // }
        }
      })
    },
    changeTrainingType(item = null) {
      if (item) {
        this.selected_type = item
      } else {
        this.selected_type.id = 0
        this.selected_type.name = this.$t('education.all_categories')
      }

      this.applyFilter()
    }
  },
  watch: {
    "filter.search": function (val) {
      this.page = 1;
      this.debouncedFilterSearch();
    },
    searchQuery: _.debounce(function () {
      this.page = 1
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.searchUser(this.searchQuery);
      }
    },
    successMessage() {
      this.debouncedResetValues();
    },
    errorMessages() {
      this.debouncedResetValues();
    },
  },
  beforeDestroy() {
    const modal = UIkit.modal("#shareSettings");
    if (modal) modal.$destroy(true);
  },
  mounted() {
    if (!this.CatalogTrainingVisible) {
      this.$router.push({ name: 'company.dashboard' })
    }
    this.selected_type_name = this.$t('education.all_categories')
    let self = this;
    store.dispatch(_TRAINING_KIND_MODULE + '/' + GET_TRAINING_KINDS, {
      url: TRAINING_KIND_URL
    });

    this.$store.dispatch(GET_ITEMS, {
      url: this.trainingCategoryUrl,
      acceptPromise: true,
    }).then(
      (response) => {
        if (response.status) {
          self.educationTypes = response.data;
          self.educationTypes.unshift({ id: 0, name: this.$t('education.all_categories') });
        }
      }
    );
    if (this.superAdmin()) {
      this.$store.dispatch(MODULE_COMPANY + '/' + GET_COMPANY, {
        url: COMPANY_URL,
        filters: {
          type: this.type,
          like: this.filter.search,
          page: this.page,
          per_page: 6,
          order_by: this.filter.order_by,
          sort: this.filter.sort,
        }
      })
    }
    this.applyFilter()
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },

}

</script>

<style scoped>
ul.ks-cboxtags li input[type=checkbox] {
  height: 100%;
}

.disabled {
  pointer-events: none;
}
</style>
